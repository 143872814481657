import { Button } from "components/button"
import { TextAndImageContentBlock } from "components/content-blocks/TextAndImageContentBlock"
import { useLayout } from "contexts/components/LayoutContext"
import React from "react"
import { ImageBuilder } from "_types/AssetsImage"
import "./blog-content-block.module.scss"

interface BlogContentBlockProps {
  image?: ImageBuilder
  heading?: string
  description?: string
  slug: string
  date?: string
  blockPosition: number
}

export const BlogContentBlock = ({
  date,
  slug,
  heading,
  image,
  description,
  blockPosition,
}: BlogContentBlockProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  const path = pageSlug.startsWith("/") ? pageSlug : `/${pageSlug}`

  const ExtraContent = () => {
    return (
      <>
        {date && (
          <time
            className="fr-blog-content-block__published-date"
            dateTime={date}
          >
            {date}
          </time>
        )}
        <p className="fr-blog-content-block__description">{description}</p>
      </>
    )
  }

  const isOdd = blockPosition % 2 ? false : true

  return (
    <article className="fr-blog-content-block">
      <TextAndImageContentBlock
        heading={heading}
        extraContent={<ExtraContent />}
        image={image}
        contentBlockType="fifty_fifty_grid"
        mapRuns={blockPosition}
        isAltBlock
        hasAltBackgroundColor={isOdd}
      >
        <div className="content-block-buttons">
          <Button
            variant="outline-primary"
            to={path}
            style={{ marginTop: "auto" }}
          >
            Read More
          </Button>
        </div>
      </TextAndImageContentBlock>
    </article>
  )
}
