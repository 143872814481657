import React from "react"
import { usePagination, DOTS } from "hooks/usePagination"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { GoTriangleRight, GoTriangleLeft } from "react-icons/go"
import { Link } from "gatsby"
import "./pagination.module.scss"

interface PaginationProps {
  onPageChange: (page: number) => void
  onNextPage: () => void
  onPreviousPage: () => void
  totalCount: number
  currentPage: number
  pageSize: number
  siblingCount?: number
  hasTextInBtnController?: boolean
}

// Next and Previous buttons could be chrevron icons - add a variant for this use case instead of removing next and forward directly
export const Pagination = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onNextPage,
  onPreviousPage,
  siblingCount = 1,
  hasTextInBtnController = false,
}: PaginationProps) => {
  const { size } = useWindowDimensions()

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 items in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  const totalPageCount = Math.ceil(totalCount / pageSize)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === lastPage

  const getCurrentPageClassName = (pageNumber: number) => {
    if (pageNumber === currentPage) return " fr-pagination__page-link--active"
    return ""
  }

  return (
    <div className="fr-pagination">
      {/* Left navigation arrow */}
      {currentPage === 1 ? (
        <div className="fr-pagination__disabled-btn">
          <GoTriangleLeft />
          {hasTextInBtnController && <>Prev{size >= 1366 && `ious`}</>}
        </div>
      ) : (
        <Link
          to={`/blog${
            currentPage === 2 || currentPage === 1
              ? ""
              : `/page-${currentPage - 1}`
          }`}
          id="button"
          className="fr-pagination__previous-btn"
          type="button"
          aria-label="Previous"
        >
          <GoTriangleLeft />
          {hasTextInBtnController && <>Prev{size >= 1366 && `ious`}</>}
        </Link>
      )}

      <ul className="fr-pagination__page-list">
        {paginationRange.map(pageNumber => {
          // If the pageItem is a DOT, render the DOTS unicode character

          if (pageNumber == DOTS) {
            return (
              <li
                key={`page-${pageNumber}`}
                className="fr-pagination__page-link fr-pagination__page-link--dots"
              >
                &#8230;
              </li>
            )
          }

          if (typeof pageNumber === "string") return // This line is here to make typescript happy about "..." since the pageNumber will always be a number in the next return block.

          // Render our Page Pills
          return (
            <li
              key={`page-${pageNumber}`}
              className={`fr-pagination__page-link ${getCurrentPageClassName(
                pageNumber
              )}`}
            >
              <Link
                id="button"
                to={`/blog${pageNumber === 1 ? `` : `/page-${pageNumber}`}`}
              >
                {pageNumber}
              </Link>
            </li>
          )
        })}
      </ul>

      {/*  Right Navigation arrow */}
      {currentPage === totalPageCount ? (
        <div className="fr-pagination__disabled-btn">
          {hasTextInBtnController && <>Next</>}
          <GoTriangleRight />
        </div>
      ) : (
        <Link
          to={`/blog${
            currentPage === totalPageCount
              ? `/page-${totalPageCount}`
              : `/page-${currentPage + 1}`
          }`}
          id="button"
          className="fr-pagination__next-btn"
          type="button"
          aria-label="Next"
        >
          {hasTextInBtnController && <>Next</>}
          <GoTriangleRight />
        </Link>
      )}
    </div>
  )
}
